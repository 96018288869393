<template>
    <div>
        <div class="selector-container" :class="[$mq]" @click="openPopup()">
            <span v-if="selectedItem.v" class="selector-color" :style="{ background: selectedItem.c }" :class="[{ 'no-color': !selectedItem.c }]"></span>
            <span class="selector-item">
                {{ selectedItem.v ? selectedItem.v : translation('assets.selector_popup.title') }}
            </span>
        </div>
        <div class="popup-container blocking popup-small" v-if="isVisiblePopup">
            <div class="popup">
                <div class="list">
                    <SearchBar customClass="selector-popup" v-if="items.length >= 6" @on-search="filterItems"></SearchBar>
                    <div class="item-list clear-value" @click="resetValue({ v: null, c: null })">{{ translation('assets.selector_popup.value-zero') }}</div>
                    <div class="items-list" :class="{ 'mobile-landscape': isApp || ['portrait'].includes($mq) }">
                        <div class="item-list" v-for="item in itemsFiltered" @click="changeValue(item)" :class="[{ selected: selectedItem === item }]">
                            <span class="selector-color" :style="{ background: item.c }" :class="[{ 'no-color': !item.c }]"></span>
                            <span class="selector-item">{{ item.v }}</span>
                        </div>
                    </div>
                    <Button bType="cancel" customClass="selector-cancel-btn" :bCallback="closePopup"></Button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SearchBar from '@/components/domain/multiaccount/searchbar.vue'
import Button from '@/components/ui/Button.vue'
import i18n from '@/i18n'
export default {
    components: {
        SearchBar,
        Button
    },
    name: 'SelectorPopup',

    props: {
        items: {
            type: undefined,
            default: []
        },
        value: {
            type: undefined,
            default: null
        },
        canClicked: {
            type: undefined,
            default: true
        }
    },

    data() {
        return {
            selectedItem: { c: true, v: 'Selecciona una opción' },
            isVisiblePopup: false,
            itemsFiltered: []
        }
    },

    methods: {
        openPopup() {
            if (this.canClicked === true) {
                this.isVisiblePopup = true
                this.$store.commit('setSelectPopup', true)
            }
        },
        filterItems(e) {
            const target = e.target.value
            this.itemsFiltered = this.items.filter((item) => item.v.toLowerCase().includes(target.toLowerCase()))
        },
        load() {
            if (this.value) {
                this.selectedItem = this.items.find((item) => item.v == this.value)
            }

            this.itemsFiltered = this.items
        },
        changeValue(item) {
            this.isVisiblePopup = false
            this.selectedItem = item

            this.$emit('input', item.v)
        },
        resetValue(item) {
            this.isVisiblePopup = false
            this.selectedItem = item

            this.$emit('reset', item.v)
        },
        closePopup() {
            this.isVisiblePopup = false
            this.itemsFiltered = this.items
            this.$store.commit('setSelectPopup', false)
        },
        translation(key) {
            return i18n.t(key)
        },
        isOldDate(date) {
            const today = new Date()
            const dateToCompare = new Date(date)
            return dateToCompare < today
        }
    },

    computed: {
        isApp() {
            return this.$store.state.getIsApp
        }
    },

    created() {
        this.load()
    }
}
</script>

<style lang="scss" scoped>
.selector-container {
    width: 100%;
    height: 100%;
    background-color: $color-selector;
    padding: 8px;
    padding-right: 26px;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    @include border-radius(3px);
    @include font-size(0.875rem);
    display: flex;
    align-items: center;
    gap: 5px;

    &.portrait {
        width: 160px;
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        background: img('down_caret_aaaaaa.svg') no-repeat center;
        background-size: contain;
        width: 10px;
        height: 10px;
    }

    .selector-item {
        white-space-collapse: collapse;
        text-wrap: nowrap;
        min-height: 1.2em;
        color: $color-letter-selector;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .selector-color {
        padding: 5px;
        @include border-radius(100%);
        margin-left: 8px;

        // &.no-color {
        //     border: 1px solid $color-selector2;
        //     position: relative;
        // }
    }
}
.popup-container .popup {
    max-width: 550px;
}
.list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;

    .selector-cancel-btn {
        width: 100%;
        margin: 0px;
        background-color: $color-primary-700;
        color: $color-white;
        background-image: none;
    }

    .item-list {
        padding: 8px;
        display: flex;
        align-items: center;
        gap: 12px;
        cursor: pointer;
        width: 100%;
        margin: 8px 0;
        border: 1px solid $color-neutral-300;
        @include border-radius(4px);
        transition: all 0.3s ease-in-out;

        &:hover {
            background-color: $color-selector;
        }

        &.selected {
            background-color: $color-neutral-200;
        }

        &.clear-value {
            color: $color-letter-selector;
            width: 100%;
            padding-right: 8px;
        }
    }
    .items-list {
        margin-top: 2px;
        max-height: 280px;
        overflow-y: auto;
        width: 100%;

        &.mobile-landscape {
            max-height: 205px;
        }
        .selector-color {
            padding: 5px;
            @include border-radius(100%);
            margin-left: 8px;

            &.no-color {
                border: 1px solid $color-selector2;
                position: relative;
            }
        }

        .selector-item {
            white-space-collapse: collapse;
            text-wrap: nowrap;
            min-height: 1.2em;
            color: $color-letter-selector;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
</style>
