<template>
    <div class="search-in-report">
        <img
            src="img/icons/search-button-icon-black.svg"
            @click="
                () => {
                    $emit('magnifying-glass')
                }
            "
            alt="search icon"
            class="search-icon"
        />
        <input
            type="text"
            @input="
                (event) => {
                    $emit('searchInputChange', event.target.value)
                }
            "
            :placeholder="$t('nvgt.report.search_placeholder')"
        />
        <div v-if="showNoResultsMsg" class="no-results-msg">
            <p>{{ $t('nvgt.report.no_results') }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        showNoResultsMsg: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
.search-in-report {
    position: relative;
    width: 100%;
    margin: 0;
    background-color: white;
    border-radius: 4px;
    height: 30px;

    .search-icon {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 8px;
        left: 8px;
    }

    input {
        display: block;
        width: 100%;
        max-width: 100%;
        padding: 0 4px 0 29px;
        height: 100%;
    }

    .no-results-msg {
        height: 16px;
        position: relative;

        p {
            position: absolute;
            top: 4px;
            font-size: 12px;
            color: $color-neutral-600;
        }
    }
}
</style>
