<template>
    <div id="content" class="report report-checklist report-page" :class="[$mq, { menuOpen: !isExpanded && userIsLogged }]">
        <!-- MOBILE top buttons -->
        <div v-if="isMobile" class="btn-actions-mobile">
            <div class="left-buttons">
                <button class="icon-button white-button" v-if="isReportLogout" @click="goBack" :aria-label="$t('button.back')">
                    <img src="img/icons/back_icon.svg" />
                </button>
            </div>

            <div class="search-bar-container">
                <SearchBar
                    v-if="isMobileSearchBarOpen"
                    :showNoResultsMsg="showNoResultsMsg"
                    @searchInputChange="updateSearchValue"
                    @magnifying-glass="
                        () => {
                            isMobileSearchBarOpen = false
                        }
                    "
                />
                <button v-else @click="() => (isMobileSearchBarOpen = true)" class="icon-button white-button" :aria-label="$t('menu_aux.action.search')">
                    <img src="img/icons/search-button-icon-black.svg" />
                </button>
            </div>

            <div class="right-buttons">
                <!-- Share button -->
                <ShareURL />
                <!-- Print button -->
                <button class="icon-button blue-button" @click="doPrint" :aria-label="$t('nvgt.report.print')">
                    <img src="img/icons/print_pdf.svg" />
                </button>
                <!-- Open Display Options Sidebar button -->
                <button class="icon-button grey-button" @click="isRightSidebarOpen = true">
                    <img src="img/icons/back_icon.svg" />
                </button>
            </div>
        </div>

        <!-- landscape top buttons (tablets and desktop) -->
        <div v-else class="btn-actions">
            <div class="left-buttons hide">
                <Button v-if="isReportLogout" bType="back" :bCallback="goBack" />
            </div>
            <div class="bar-actions hide">
                <ShareURL />
                <Button bType="print" :bCallback="doPrint" bLabel="nvgt.report.print" />
            </div>
        </div>

        <!-- report main container -->
        <div class="report-main-container" :class="[$mq]">
            <div class="left-container">
                <div class="sidebar-left" :class="{ closedLeftSidebar: !isLeftSidebarOpen }" v-if="Object.values(items).length > 1">
                    <div class="sidebar-left-data-table">
                        <v-data-table
                            v-if="Object.values(items).length > 1"
                            :headers="headers"
                            fixed-header
                            :items="Object.values(items)"
                            hide-default-footer
                            :page.sync="pagination.page"
                            :items-per-page="pagination.itemsPerPage"
                            @page-count="pageCount = $event"
                        >
                            <template #item.title="item">
                                <div class="table-row" @click="changeReport(item.item.id)">
                                    <div class="top">
                                        <span class="title-checklist" v-tooltip="{ content: item && item.item && item.item.title ? item.item.title : '' }">
                                            {{ item.item.title }}
                                            <span v-if="item.item.delay" class="delay">{{ leftTime(item.item.delay) }}</span>
                                        </span>
                                        <span :class="scoreColors(item.item.score)" class="score">{{ item.item.score }}%</span>
                                    </div>

                                    <div class="bottom">
                                        <span class="localization">{{ item.item.location_name }}</span>
                                        <span class="date" v-html="spanishDateFormat(item.item.business_date)"></span>
                                    </div>
                                </div>
                            </template>
                        </v-data-table>
                        <!-- <div id="pagination" v-if="!(noChanges && items.length == 0)"> TODO REVIEW "noChanges" -->
                        <div id="pagination" v-if="!(items.length == 0)">
                            <v-pagination v-model="pagination.page" color="#2c52c4" :length="pageCount" :total-visible="maxPageVisibles"></v-pagination>
                        </div>
                    </div>
                </div>
                <Button
                    v-if="Object.values(items).length > 1"
                    class="leftSiderbar-Controler"
                    :class="{ closedSidebar: !isLeftSidebarOpen }"
                    :bType="isLeftSidebarOpen ? 'leftArrow' : 'rightArrow'"
                    :bCallback="toggleLeftSidebar"
                >
                </Button>
            </div>

            <div class="main-content" :class="{ fullwidth: Object.values(items).length == 1 }">
                <Banner v-if="editActive" @templateToggle="toggleTemplate"></Banner>
                <template v-if="items[$route.params.id] && items[$route.params.id].items && bannerBType === 'editOn'">
                    <ReportEdition
                        :gId="$route.params.id"
                        :cType="type"
                        :key="count"
                        :data="items[$route.params.id]"
                        ref="reportPrint"
                        :showDetails="showDetails"
                        :showAnswers="showAnswers"
                        :printOpts="printOpts"
                        :previewOpts="previewOpts"
                        :reportType="1"
                        @setDateTimeframe="setDateTimeframe"
                    ></ReportEdition>
                </template>
                <template v-if="items[$route.params.id] && items[$route.params.id].items && bannerBType === 'editOff'">
                    <Report
                        :key="count"
                        :data="items[$route.params.id]"
                        ref="reportPrint"
                        :showDetails="showDetails"
                        :showAnswers="showAnswers"
                        :showNoApply="showNoApply"
                        :showClock="showClock"
                        :printOpts="printOpts"
                        :previewOpts="previewOpts"
                        :reportType="1"
                        :showImagesResume="showImagesResume"
                        :showNotes="showNotes"
                        :userSearch="userFilterSearch"
                        @toggleNoResultsMsg="handleToggleNoResultsMsg"
                    />
                </template>
            </div>
            <div class="right-container" :class="{ hidden: isMobile && !isRightSidebarOpen, blocked: isRightSidebarOpen }">
                <Button
                    class="rightSiderbar-Controler"
                    :class="{ closedSidebar: !isRightSidebarOpen }"
                    :bType="isRightSidebarOpen ? 'rightArrow' : 'leftArrow'"
                    :bCallback="toggleRightSidebar"
                >
                </Button>
                <ReportSidebar
                    :displaySettings="displaySettings"
                    @checkboxChange="updateDisplaySettings"
                    @searchInputChange="updateSearchValue"
                    :showNoResultsMsg="showNoResultsMsg"
                    :isRightSidebarOpen="isRightSidebarOpen"
                />
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import Banner from '@/components/ui/Banner'
import Report from '@/components/ui/Report'
import ReportEdition from '@/components/ui/ReportEdition'
import ShareURL from '@/components/ui/ShareURL'
import ReportSidebar from '@/components/ui/ReportSidebar.vue'
import { TYPE } from '@/constants'
import SearchBar from '../../../ui/SearchBar.vue'

export default {
    name: 'ReportChecklist',
    props: {
        reports: { type: Array }
    },
    components: {
        Banner,
        Report,
        ReportEdition,
        ShareURL,
        ReportSidebar,
        SearchBar
    },
    data() {
        return {
            type: TYPE.TASK,
            isLeftSidebarOpen: false,
            isRightSidebarOpen: true,
            type: 1,
            bannerBType: 'editOff',
            route_name: 'ReportChecklist',
            route: 'Checklist',
            count: 0,
            checklistWithItems: {},
            printOpts: {
                showing: false,
                attach: true,
                feed: true
            },
            previewOpts: {
                showing: false,
                note: true,
                desc: true
            },
            pagination: {
                page: 1,
                itemsPerPage: 15
            },
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            maxPageVisibles: 10,
            headers: [
                {
                    text: this.$t('supervise.checklists.table_headers.checklists'),
                    align: 'start',
                    sortable: false,
                    value: 'title',
                    width: '30%',
                    class: 'header-table'
                }
            ],
            isReportLogout: false,
            showClock: true,

            // report display settings
            showAnswers: localStorage.getItem('checklistsDisplayNoAnswered') == 'false' ? false : true,
            showDetails: localStorage.getItem('checklistsShowDetails') == 'false' ? false : true,
            showNotes: localStorage.getItem('checklistsShowNotes') == 'false' ? false : true,
            showNoApply: localStorage.getItem('checklistsShowNoApply') == 'false' ? false : true,
            showImagesResume: localStorage.getItem('checklistsShowImagesResume') == 'false' ? false : true,

            // search in report
            userFilterSearch: '',
            showNoResultsMsg: false,
            isMobileSearchBarOpen: false,
            // mobile
            windowWidth: window.innerWidth
        }
    },
    computed: {
        editActive() {
            return this.$store.getters['login/getReportEditionAvailable'] == 1
        },
        items() {
            if (this.reports != undefined) {
                var result = this.$store.getters['tasks/getListReports'](this.reports)
                return result
            } else return false
        },
        isExpanded() {
            return this.$store.state['menu_tools_is_expanded']
        },
        displaySettings() {
            return [
                { type: 'answers', value: this.showAnswers, label: i18n.t('nvgt.report.hide_no_answered') },
                { type: 'details', value: this.showDetails, label: i18n.t('nvgt.report.hide_details') },
                { type: 'show_notes', value: this.showNotes, label: i18n.t('nvgt.report.hide_notes') },
                { type: 'no_apply', value: this.showNoApply, label: i18n.t('nvgt.report.hide_noApply') },
                { type: 'images_resume', value: this.showImagesResume, label: i18n.t('nvgt.report.hide_imageResume') }
            ]
        },
        PDFFromServerFF() {
            const PDFFromServerFF = parseInt(this.$store.getters['account/getReportPdf'])
            if (PDFFromServerFF == 1) {
                return true
            }
            return false
        },
        isMobile() {
            return this.windowWidth <= 767
        },
        userIsLogged() {
            return this.$store.getters['login/getHasLogin']
        }
    },
    methods: {
        load() {
            var self = this

            if (this.reports != undefined) {
                var sReportsID = JSON.stringify(this.reports)

                if (!this.$route.params.id) {
                    this.$router.push({
                        name: 'ReportChecklist',
                        params: { id: this.reports[0] }
                    })
                }

                this.$store.dispatch('tasks/loadReportList', { ids: sReportsID }).then(function (response) {})
                this.$store.dispatch('tasks/superviseReportLog', { checklists: this.reports.join() }).then(function (response) {})
                if (Object.values(self.items).length > 1) {
                    this.isLeftSidebarOpen = true
                    this.isRightSidebarOpen = false
                }
            }
        },

        leftTime(valDate) {
            var num = valDate
            var hours = num / 60
            var rhours = Math.floor(hours)
            var minutes = (hours - rhours) * 60
            var rminutes = Math.round(minutes)
            var result = ''
            if (rhours == 0) {
                result = '+' + rminutes + ' min'
            } else if (rhours > 1) {
                result = '+' + rhours + 'h y ' + rminutes + ' min'
                if (rminutes == 0) {
                    result = '+' + rhours + 'horas'
                }
            } else {
                result = '+' + rhours + 'h y ' + rminutes + ' min'
                if (rminutes == 0) {
                    result = '+' + rhours + 'h'
                }
            }
            return result
        },

        changeReport(id) {
            if (id === this.$route.params.id) {
                return
            }

            this.$router.push({ name: 'ReportChecklist', params: { id: id } })
            this.$overlay.hide()

            document.getElementsByClassName('report-container')[0].scroll({ top: 0, behavior: 'smooth' })
        },

        goBack() {
            this.$overlay.show()
            this.$emit('back')

            if (id != this.$route.params.id) {
                this.$router.push({ name: 'ReportChecklist', params: { id: id } })
            }
        },

        goBack() {
            this.$emit('back')
        },

        scoreColors(score) {
            if (score >= 100) {
                return 'done'
            } else if (score > 75) {
                return 'progress'
            } else if (score > 25) {
                return 'warning'
            } else {
                return 'alert'
            }
        },
        spanishDateFormat(valDate) {
            var localLocale = moment(valDate * 1000)
            // moment.locale('es')
            localLocale.locale(false)
            return localLocale.format('DD/MM/YY')
        },

        showExportByEmailPopup() {
            const self = this

            const user = self.$store.getters['loginUser/getLocalUser']
            const email = user.email
            const messageWithEmail = self.$t('supervise.reports.export.popup_email.message', { email: `<b>${email}</b>` })
            const messageWithoutEmail = self.$t('supervise.reports.export.popup_email.message2')

            self.$popup.messageWithButton({
                title: self.$t('supervise.reports.export.popup_email.title'),
                message: email ? messageWithEmail : messageWithoutEmail,
                callCancel: () => {
                    self.$popup.close()
                }
            })
        },

        doPrint() {
            const PDFFromServerFF = this.PDFFromServerFF

            const reportsQuantity = Object.values(this.items).length

            if (PDFFromServerFF || reportsQuantity === 1) {
                const self = this

                const reportsIds = Object.values(self.items).map((report) => report.id)
                self.$store.commit('reports/setReportsToExportIds', reportsIds)

                const options = {
                    answers: this.showAnswers,
                    details: this.showDetails,
                    show_notes: this.showNotes,
                    no_apply: this.showNoApply,
                    images_resume: this.showImagesResume
                }

                // const reportsQuantity = reportsIds.length
                // if (reportsQuantity > 50) {
                //     self.$store.dispatch('reports/exportReports', { type: TYPE.TASK, options: options })
                //     self.showExportByEmailPopup()
                // } else {
                // if (reportsQuantity >= 10) {
                //     self.$popup.exportLoading()
                // } else self.$overlay.loading()

                // display loader
                self.$overlay.loading()

                self.$store.dispatch('reports/exportReports', { type: TYPE.TASK, options: options }).then((response) => {
                    if (!response.status) {
                        // close popup or loader
                        // if (reportsQuantity >= 10) {
                        //     self.$popup.close()
                        // } else self.$overlay.hide()

                        // close loader
                        self.$overlay.hide()

                        if (response.error.code == -2) {
                            // inform that is not possible to export and ask user to change the filters
                            self.$popup.messageWithButton({
                                title: this.$t('supervise.reports.export.popup_avoid.title'),
                                message: this.$t('supervise.reports.export.popup_avoid.message2'),
                                customClass: 'error',
                                callCancel: () => {
                                    // close popup
                                    self.$popup.close()
                                }
                            })
                        } else {
                            // inform user that PDFs will be sent by email:
                            self.showExportByEmailPopup()
                        }

                        return
                    } else {
                        // close popup or loader
                        // if (reportsQuantity >= 10) {
                        //     self.$popup.close()
                        // } else self.$overlay.hide()

                        // close loader
                        self.$overlay.hide()

                        // open PDFs in a new tab
                        window.open(response.url, '_blank')
                    }
                })
            } else {
                this.$snackbar.close()
                this.$store.dispatch('tasks/supervisePdfPrintlog', { id: this.$route.params.id })
                window.print()
                this.count += 1
                // report print scroll to bottom
                this.$refs.reportPrint.$el.scrollTop = this.$refs.reportPrint.$el.scrollHeight
                this.$refs.reportPrint
            }
        },
        toggleTemplate() {
            if (this.bannerBType === 'editOn') {
                this.bannerBType = 'editOff'
            } else {
                this.bannerBType = 'editOn'
            }
        },
        setDateTimeframe(date, timeframe) {
            // this.date = moment(date).set({ minutes: 0, seconds: 0 })
            this.date = moment(moment(date).format('YYYY-MM-DD'))
            this.currentTimeframe = timeframe
        },

        updateDisplaySettings(params) {
            const { type, value } = params

            if (type === 'answers') {
                this.showAnswers = value
                localStorage.setItem('checklistsDisplayNoAnswered', value)
                return
            }

            if (type === 'details') {
                this.showDetails = value
                localStorage.setItem('checklistsShowDetails', value)
                return
            }

            if (type === 'show_notes') {
                this.showNotes = value
                localStorage.setItem('checklistsShowNotes', value)
                return
            }

            if (type === 'no_apply') {
                this.showNoApply = value
                localStorage.setItem('checklistsShowNoApply', value)
                return
            }

            if (type === 'images_resume') {
                this.showImagesResume = value
                localStorage.setItem('checklistsShowImagesResume', value)
                return
            }
        },

        updateSearchValue(value) {
            if (!value) {
                this.userFilterSearch = ''
            }

            this.userFilterSearch = value
        },
        handleToggleNoResultsMsg(value) {
            this.showNoResultsMsg = value
        },
        toggleLeftSidebar() {
            this.isLeftSidebarOpen = this.isLeftSidebarOpen ? false : true
            if (this.isLeftSidebarOpen) {
                this.isRightSidebarOpen = false
            }
        },
        toggleRightSidebar() {
            this.isRightSidebarOpen = !this.isRightSidebarOpen
            if (this.isRightSidebarOpen) {
                this.isLeftSidebarOpen = false
            }
        },
        handleResize() {
            this.windowWidth = window.innerWidth
        }
    },
    watch: {},
    created() {
        this.load()
        this.isReportLogout = this.$store.getters['records/getReportAccess'] ? true : false
        if (this.$store.getters['login/getHasLogin']) {
            this.isReportLogout = true
        }

        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        if (this.isMobile) {
            this.isRightSidebarOpen = false
        }
    },
    beforeDestroy() {},
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style lang="scss" scoped>
#content.report-checklist {
    padding: 0;

    .report-container {
        // height: calc(90vh - 80px);
        height: calc(90vh - 100px);

        @media (min-width: 768px) {
            height: calc(90vh - 114px);
        }

        @media (min-width: 1025px) {
            margin: 60px 0px 30px 0px;
            overflow-x: hidden;
        }
    }

    > .report-main-container {
        padding-top: 0px;
        position: absolute;
        width: 100%;
        // top: 73px;
        top: 60px;
        display: flex;
        justify-content: space-between;

        @media (min-width: 1025px) {
            top: 73px;
        }

        .left-container {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            .sidebar-left-data-table {
                height: calc(100% - 70px);
            }
            .leftSiderbar-Controler {
                margin-top: 16px;
                margin-left: -24px;
                height: 32px;
                background-position: 0px center;
                background-size: 18px;
                background-color: transparent;
                z-index: 2;
            }
            .closedSidebar {
                margin-left: -6px;
                margin-right: 0px;
            }
        }
        .closedLeftSidebar {
            width: 12px;
            background-color: $color-white;
            margin-left: 8px;
            box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
        }
        .main-content {
            width: 100%;
            max-width: 100%;

            @media (min-width: 1160px) {
                width: calc(100% - 308px);
                left: 0;
            }
        }
        .right-container {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            .rightSiderbar-Controler {
                margin-top: 16px;
                margin-right: 272px;
                height: 32px;
                background-position: 0px center;
                background-size: 18px;
                background-color: transparent;
                z-index: 2;
            }
            .closedSidebar {
                margin-right: 6px;
            }
        }
        .switch-input {
            margin: 0;
            position: absolute;
            right: 6px;
            top: 10px;
            // background-color: $color-neutral-600 !important;
        }

        .small-width {
            width: calc(100% - 366px);
            left: 0;
        }
    }
}

.main-content {
    flex-wrap: wrap;
}
</style>
