<template>
    <div>
        <div class="report-container" :class="{ opened: openReport }">
            <div v-show="openReport" class="report toPrint" :class="$mq">
                <div class="generated_date">{{ generatedDate() }}</div>
                <div class="header">
                    <div class="brand" v-if="account.avatar"><img :src="account.avatar" alt="" /></div>

                    <div class="brand text" v-else>{{ account.account_name }}</div>
                    <div class="brand alex">
                        <img class="image" src="~@/../public/img/logo_andy.png" alt="Andy" />
                    </div>
                </div>

                <div class="report-header">
                    <div class="report-info">
                        <template v-if="reportType == 1 || reportType == 4">
                            <h2 class="report-location">{{ value.location_name }}</h2>
                            <h2 class="report-date" v-if="data.start_date" v-html="resumeDate(data)"></h2>
                            <h2 class="report-employee" v-if="reportType == 4 && data.employees">{{ data.employees[Object.keys(data.employees)[0]].name }}</h2>
                            <h2 class="report-date">
                                <span class="close-date" v-if="reportType == 4 && data.complete_date"
                                    >{{ $t('report.issue_closed', { date: data.complete_date.format('dddd DD MMM YYYY hh:ss') }) }}
                                    <img class="avatar" :src="cssImage + 'avatar/' + data.employee_ref.avatar" />
                                    {{ data.employee_ref.name + ' ' + data.employee_ref.surname }}
                                </span>
                            </h2>
                        </template>
                        <template v-else-if="reportType == FORMAT.YESNO">
                            <h2 class="report-location">{{ value.location_name }}</h2>
                            <h2 class="report-date" v-if="data.business_date" v-html="resumeDate(data.business_date['_i'])"></h2>
                            <h2 class="report-employee" v-if="data.employee_ref">{{ getEmployee(data.employee_ref.id).name }}</h2>
                        </template>
                        <template v-else-if="reportType == 3">
                            <h2 class="report-location">{{ data.location_name }}</h2>
                            <h2 class="report-date" v-if="data.complete_date" v-html="resumeDate(data.complete_date)"></h2>
                            <UserTag :userId="data.employee_ref.id" size="medium" styleOf="block" :hasAvatar="true"></UserTag>
                        </template>

                        <!-- REPORT STATUS -->
                        <div
                            v-if="reportType == 3 && data && data.status == 0"
                            class="report-status intime"
                            v-tooltip="$t('nvgt.report.timing.intime_tooltip')"
                        >
                            {{ $t('template.general.status.template_draft') }}
                        </div>
                        <div
                            v-else-if="reportType == 1 && reportStatus() == 'intime' && !data.complete_date"
                            class="report-status intime"
                            v-tooltip="$t('nvgt.report.timing.intime_tooltip')"
                        >
                            {{ $t('nvgt.report.timing.intime') }}
                        </div>
                        <div
                            v-else-if="reportType == 1 && reportStatus() == 'future'"
                            class="report-status future"
                            v-tooltip="$t('nvgt.report.timing.future_tooltip')"
                        >
                            {{ $t('nvgt.report.timing.future') }}
                        </div>
                    </div>

                    <div class="report-data">
                        <h2 class="report-title" :class="reportType == 3 ? 'fullwidth' : false">{{ value.title }}</h2>
                        <div class="left">
                            <template v-if="reportType == 1">
                                <template v-if="data.total == 0">
                                    <div class="na">{{ $t('nvgt.report.answer_na') }}</div>
                                </template>
                                <template v-else>
                                    <div class="score-number">{{ data.completed }}/{{ data.total }}</div>
                                    <div v-if="reportStatus() != 'future' && data.total != 0" class="score-percent" :class="scoreColors(data.score)">
                                        {{ data.score }}%
                                    </div>
                                </template>
                            </template>
                            <template v-if="reportType == 2">
                                <div class="score-audit">
                                    <b>{{ data.score }}%</b>
                                </div>
                                <div class="status-audit" :class="getColorState(data.status)" v-html="getStateAudits(data.status)"></div>
                            </template>
                            <template v-else-if="reportType == 4">
                                <States :currentState="data.status" :issueID="$route.params.id"></States>
                            </template>
                        </div>
                    </div>
                    <div class="report-alerts" v-if="reportType == 1">
                        <div v-if="data.resume && data.resume.is > 0" class="counter incidents">
                            <span>{{ data.resume.is }}</span
                            >{{ $tc('nvgt.report.alerts_incident_number', data.resume.is) }}
                        </div>
                        <div v-if="data.resume && data.resume.a > 0" class="counter alerts">
                            <span>{{ data.resume.a }}</span
                            >{{ $tc('nvgt.report.alerts_number', data.resume.a) }}
                        </div>
                        <div v-if="data.resume && data.resume.it > 0 && reportStatus() != 'intime' && reportStatus() != 'future'" class="counter uncompleted">
                            <span>{{ data.resume.it }}</span
                            >{{ $tc('nvgt.report.uncompleted_number', data.resume.it) }}
                        </div>
                    </div>
                    <div class="report-alerts" v-else-if="reportStatus() != 'future'">
                        <div v-if="data.resume && data.resume.it > 0 && reportStatus() != 'intime'" class="counter uncompleted">
                            <span>{{ data.resume.it }}</span
                            >{{ $tc('nvgt.report.uncompleted_number', data.resume.it) }}
                        </div>
                    </div>
                </div>

                <div v-if="reportType == 4 && refTask && refTask.check_title">
                    <div class="reference">
                        <div class="andy"></div>
                        <div class="message" v-html="$t('nvgt.report.task_reference', refTask)"></div>
                    </div>
                </div>

                <!-- TODO if justify msg show msg of justify -->
                <div class="justify" v-if="value && value.justify_id">
                    <div class="andy"></div>
                    <div>
                        <p
                            class="justify-text"
                            v-html="
                                $t('nvgt.report.justify', {
                                    employee: `<span>${getEmployee(value.justify_user_id).name} ${getEmployee(value.justify_user_id).surname}</span>`,
                                    date: `<span>${formatDate(value.justify_date)}</span>`,
                                    reason: `<span>${reasonJustify[value.justify_id].name} &quot;${value.justify_msg.trim()}&quot;</span>`
                                })
                            "
                        ></p>
                    </div>
                </div>
                <template>
                    <div v-if="check" ref="container" id="detail-content" class="content scrollbar right" :class="$mq">
                        <div class="template-sections" v-if="sections.length > 1">
                            <Template-sections :sections="sections"></Template-sections>
                        </div>
                        <div class="detail-box" :class="[{ 'with-sections': sections.length > 1 }, { 'opened-info': infoIsOpen }]">
                            <div
                                class="exam"
                                id="exam"
                                :class="[
                                    editable ? 'performing' : '',
                                    { 'with-sections': sections.length > 1 },
                                    { 'hide-scrollbar': ['portrait'].includes($mq) },
                                    { scrollbar: ['landscape', 'desktop'].includes($mq) }
                                ]"
                            >
                                <Banner v-if="dependency_tpl" type="error" :message="getStringVariable('answer.dependency_tpl', dependency_tpl.title)"></Banner>

                                <div v-if="check.need_justification && !check.justify_id && check.completed != check.total" class="justify-description">
                                    <div class="alex"></div>
                                    <div class="message keep-format" v-html="getString('answer.justify.pending_desc')"></div>
                                    <div class="action" @click="justify">
                                        {{ getString('answer.justify.justify_it') }}
                                    </div>
                                </div>
                                <div v-if="check.need_justification && check.justify_id" class="justify-description">
                                    <div class="alex"></div>
                                    <div class="message keep-format" v-html="getStringVariables('answer.justify.justified_desc', reasonDesc)"></div>
                                </div>

                                <div v-if="check.description" class="check-description">
                                    <div class="message keep-format" v-html="descriptionParse(check.description)" @click="view_more = !view_more"></div>
                                </div>

                                <div v-if="check.recover && editable && !check.complete_date && checklistAvailability()" class="get-last-values">
                                    <span class="message" @click="recoverValues()">{{ $t('tasks.get_last_values') }}</span>
                                </div>

                                <div v-if="check.multiple && editable" class="question complete-all">
                                    <div class="item">
                                        <div class="answer">
                                            <div class="line">
                                                <div class="statement">
                                                    <div class="answer-title keep-format" v-html="$t('tasks.multiple_resolve')"></div>
                                                </div>
                                                <div class="response">
                                                    <span class="value" :class="completeAllClasses" @click="resolveTasks()"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <template v-if="check">
                                    <Answer
                                        v-for="answer in check.items"
                                        @cleanDependency="cleanDependency"
                                        :hasDependency_tpl="dependency_tpl"
                                        :key="answer.item_id"
                                        :item="answer"
                                        :list="check"
                                        :cId="check.id"
                                        :aId="answer.item_id"
                                        :cType="type"
                                    ></Answer>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div v-else class="content hide-scrollbar right empty">
                        <EmptyPage
                            :buttonText="$t('empty_pages.return_tasks')"
                            @buttonAction="back()"
                            buttonClass="back"
                            :type="'error'"
                            :title="$t('assets.overlay.timeout.message')"
                            :description="$t('empty_pages.error_checklists')"
                        ></EmptyPage>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import States from '@/components/domain/issues/states.vue'
import { FORMAT } from '@/constants'
import i18n from '@/i18n'
import UserTag from '@/components/ui/UserTag'
import Button from '@/components/ui/Button'
import Answer from '@/components/ui/Answer'
import EmptyPage from '@/components/layout/EmptyPage'

export default {
    name: 'Report',
    props: {
        data: Object,
        printOpts: Object,
        previewOpts: Object,
        reportType: Number,
        showAnswers: Boolean,
        showDetails: Boolean,
        gId: { type: String }
    },
    components: { UserTag, States, Answer, Button, EmptyPage },
    data() {
        return {
            type: 1,
            editMode: false,
            openReport: true,
            extendedAnswers: [4, 10, 11],
            FORMAT: FORMAT,
            refTask: false,
            cssImage: css_image_url,
            dependency_tpl: false,
            collapsedInfo: false,
            view_more: false,
            infoIsOpen: false
        }
    },
    computed: {
        check() {
            var check = this.$store.getters['tasks/getReportById'](this.gId)
            if (check) {
                // SAVE TIMEFRAMEID AND DATE OF CHECKLIST
                this.$emit('setDateTimeframe', check.schedule.start, check.timeframe_id)
            } else {
                this.$bar.hide()
            }
            return check
        },
        state() {
            var now = moment()
            var start = moment(this.check.limit.start)
            var end = moment(this.check.limit.end)

            var state = false

            if (start.isAfter(now)) state = 'future'
            else if (end.isSameOrBefore(now)) state = 'past'
            else if (start.isSameOrBefore(now) && end.isSameOrAfter(now)) {
                state = 'intime'
            }

            this.check.state = state

            return state
        },
        sections() {
            var sections = []
            for (var i in this.check.items) {
                if (this.check.def.item.itemsTitle.indexOf(this.check.items[i].type) != -1) {
                    sections.push(this.check.items[i])
                }
            }
            return sections
        },
        editable() {
            let now = moment()
            let limit = this.check.limit
            if (now.isBetween(limit.start, limit.end) || now.isBefore(limit.start)) {
                return true
            }
            return false
        },
        stillAvailable() {
            let now = moment()
            let limit = this.check.limit
            let schedule = this.check.schedule

            if (now.isBetween(limit.start, limit.end) && !now.isBetween(schedule.start, schedule.end) && now.isAfter(schedule.end)) {
                return true
            }
            return false
        },
        resolveTasks() {
            let self = this
            let employee = this.$store.getters['loginUser/getLocalEmployee']

            if (this.completeAllClasses == 'checked') {
                this.$store.dispatch('tasks/resetAll', { check: this.gId }).then(function (response) {
                    self.$snackbar.success()
                })
            } else {
                this.$store.dispatch('tasks/completeAll', { check: this.gId, emp: employee, date: moment().unix() }).then(function (response) {
                    self.$snackbar.success()
                })
            }
        },
        completeAllClasses() {
            return this.$store.getters['tasks/getCheckTasksState'](this.gId)
        },
        account() {
            return this.$store.getters['login/getLocalAccount']
        },
        value() {
            if (this.data.references || this.data.references != '') {
                this.loadReference(this.data)
            }
            return this.data
        },

        optionsTimeframes() {
            return this.$store.getters['tasks/getFilterTimeframe']()
        },

        optionsStates() {
            return this.$store.getters['issues/getFilterStates']()
        },

        optionsPostpose() {
            return this.$store.getters['getReasonPostpone']
        },
        reasonJustify() {
            return this.$store.getters['getReasonJustify']
        }
    },
    methods: {
        toSection(id) {
            const section = document.getElementById(id)

            switch (this.$mq) {
                case 'desktop':
                case 'landscape':
                    document.getElementById('detail-content').scrollTo({
                        top: section.offsetTop - 150,
                        behavior: 'smooth'
                    })
                    break
                case 'portrait':
                    document.getElementById('content').scrollTo({
                        top: section.offsetTop - 90,
                        behavior: 'smooth'
                    })
                    break
            }
        },
        goToReference() {
            if (this.check && this.check.data && this.check.data.ref) {
                this.$router.push({
                    name: 'SuperviseChecklist',
                    params: {
                        id: this.check.data.ref.checklist,
                        checkType: 1,
                        toTaskId: this.check.data.ref.item
                    }
                })
            }
        },
        checklistAvailability() {
            if (this.check.limit.start && this.check.limit.end) {
                // NO disponible
                if (this.dependency_tpl) {
                    return false
                }

                // disponible
                if (moment() > moment(this.check.limit.start) && moment() < moment(this.check.limit.end)) {
                    return true
                }
                // no disponible, dias antiguos
                if (moment() > moment(this.check.limit.end)) {
                    return false
                }
                // no disponible, dias futuros
                if (moment() < moment(this.check.limit.start)) {
                    return false
                }
            }
        },
        redirectToIssue(issueId) {
            this.$router.push({
                name: 'ReportIssue',
                params: {
                    id: issueId
                }
            })
        },
        cleanDependency() {
            this.dependency_tpl = false
        },
        infoOpened() {
            this.infoIsOpen = !this.infoIsOpen
        },
        hasToHaveScore(section) {
            if (section.total > 0 || section.total < 0) {
                return true
            }
            return false
        },
        // descriptionParse(msg) {
        //     var result = msg
        //     if (result) {
        //         result = result.replace(/\n/gi, ' <br> ')
        //         result = result.replace(/([^\S]|^)(((https?\:\/\/)|(http?\:\/\/)|(www\.))(\S+))/gi, function (match, space, url) {
        //             var hyperlink = url.startsWith('www') ? 'https://' + url : url
        //             return space + '<a target="_blank" href="' + hyperlink + '">' + url + '</a>'
        //         })
        //         return result
        //     }
        //     return '-'
        // },
        descriptionParse(msg) {
            if (msg.split(' ').length < 100) {
                return msgParse(msg)
            } else {
                if (this.view_more) {
                    return msgParse(msg) + ' <span class="more"> ' + this.$t('label.less') + '</span>'
                } else {
                    var maxLength = 150 // maximum number of characters to extract

                    var trimmedMsg = msg.substr(0, maxLength)

                    trimmedMsg = trimmedMsg.substr(0, Math.min(trimmedMsg.length, trimmedMsg.lastIndexOf(' ')))
                    return msgParse(trimmedMsg) + '... <span class="more">' + this.$t('label.more') + '</span>'
                }
            }
        },
        valueInPercentage(value, total) {
            var result = Math.round((value / total) * 100)
            if (isNaN(result)) {
                return 0
            }

            return result + '%'
        },

        loadReference(checklist) {
            if (checklist != undefined && checklist && checklist.references) {
                let self = this
                self.refTask = {
                    check_title: checklist.references.template_name,
                    check_date: moment(checklist.references.created_date * 1000).format('dddd DD MMM YYYY'),
                    task_title: checklist.references.item_name,
                    task_value: self.format(checklist.references.value, checklist.references.item_type, checklist.references.config)
                }
            } else {
                this.refTask = false
            }
        },
        turnOnEdit() {
            this.editMode = !this.editMode

            if (this.editMode) {
                this.$refs.button.bType = 'editOn'
                this.$refs.button.bLable = 'button.editOn'
            } else {
                this.$refs.button.bType = 'editOff'
                this.$refs.button.bLable = 'button.editOff'
            }
        },
        format(value, type, config) {
            let response = value
            switch (parseInt(type)) {
                case FORMAT.CHECK:
                case FORMAT.YESNO:
                    response = value ? '✅' : '❌'
                    break
                case FORMAT.YESNONC:
                    if (value == 1) response = '✅'
                    else if (value == 2) response = '❌'
                    else response = this.i18n.t('excel.nsnc')
                    break
                case FORMAT.DATE:
                    response = value ? moment(value).format('DD/MM/YYYY') : ''
                    break
                case FORMAT.DATETIME:
                    response = value ? moment(value).format('DD/MM/YYYY HH:mm') : ''
                    break
                case FORMAT.PHOTO:
                case FORMAT.SIGNATURE:
                    response = value ? '<img src="' + value + '"/>' : value
                    break
                case FORMAT.TEMPERATURE:
                    const units = config.units
                    response = `${value}${units}`
                    break
                case FORMAT.EMAIL:
                    response = value ? this.i18n.t('excel.email.send') : this.i18n.t('excel.email.notSend')
                    break
            }
            return response
        },

        reportStatus() {
            if (this.data && this.reportType == 1) {
                var start = moment.unix(this.data.start_date)
                var end = moment.unix(this.data.end_date)

                if (moment().isBetween(start, end)) {
                    return 'intime'
                } else if (moment().isBefore(end)) {
                    return 'future'
                } else return 'past'
            }
        },

        moment: function (date) {
            if (date) return moment(date)
            return moment()
        },

        generatedDate() {
            var now = moment()
            return this.$t('nvgt.report.generated_date', { c1: now.format('DD/MM/YYYY'), c2: now.format('HH:mm') })
        },

        resultClass(day) {
            var style = 'p' + day.score
            style += day.score == 100 ? ' success' : ' error'
            return style
        },

        scoreColors(score) {
            if (score >= 100) {
                return 'done'
            } else if (score > 75) {
                return 'progress'
            } else if (score > 25) {
                return 'warning'
            } else {
                return 'alert'
            }
        },

        stateFormat(iState) {
            let result = ''
            let status = this.optionsStates[iState]
            if (status && typeof status !== 'undefined') {
                if (status.color && status.color != null) {
                    result += "<span class='color' style='background-color: " + status.color + "'></span>"
                }
                result += "<span class='text'>" + status.name + '</span>'
            }

            return result
        },

        // AUDITS
        getStateAudits(iState) {
            var status = this.$store.getters['audits/getFilterStates'](iState)
            var result = status
            if (iState === 0) {
                result = ''
            }
            return result.name
        },

        getColorState(state) {
            var result = ''
            if (state == 1) {
                result = 'finalizada'
            }
            if (state == 2) {
                result = 'en_progreso'
            }
            if (state == 3) {
                result = 'programada'
            }
            return result
        },

        leftTime(valDate) {
            var num = valDate
            var hours = num / 60
            var rhours = Math.floor(hours)
            var minutes = (hours - rhours) * 60
            var rminutes = Math.round(minutes)
            var result = ''
            if (rhours == 0) {
                result = '+' + rminutes + ' min'
            } else if (rhours > 1) {
                result = '+' + rhours + 'h y ' + rminutes + ' min'
                if (rminutes == 0) {
                    result = '+' + rhours + 'horas'
                }
            } else {
                result = '+' + rhours + 'h y ' + rminutes + ' min'
                if (rminutes == 0) {
                    result = '+' + rhours + 'h'
                }
            }
            return result
        },

        resumeDate(day) {
            var result = ''
            if (this.reportType == FORMAT.CHECK && Object.keys(this.optionsTimeframes).length > 0) {
                result =
                    "<span class='date'>" +
                    moment(day.start_date * 1000).format('dddd DD MMM YYYY') +
                    "</span><span class='time'>" +
                    this.optionsTimeframes[day.timeframe_id].name +
                    (day.timeframe_id != 0
                        ? ' ' +
                          moment(
                              moment(moment().format('YYYY/MM/DD 00:00:00')).valueOf() + this.optionsTimeframes[day.timeframe_id].start_hour * 60 * 60 * 1000
                          ).format('HH:mm') +
                          ' - ' +
                          moment(
                              moment(moment().format('YYYY/MM/DD 00:00:00')).valueOf() + this.optionsTimeframes[day.timeframe_id].end_hour * 60 * 60 * 1000
                          ).format('HH:mm') +
                          '</span>'
                        : '')
            } else if (this.reportType == FORMAT.YESNO) {
                result = "<span class='date'>" + moment(day).format('dddd DD MMM YYYY') + '</span>'
            } else if (this.reportType == FORMAT.YESNONC) {
                result = "<span class='date'>" + moment(day * 1000).format('dddd DD MMM YYYY') + '</span>'
            } else if (this.reportType == FORMAT.TEXT) {
                result =
                    "<span class='date'>" +
                    moment(day.start_date * 1000).format('dddd DD MMM YYYY') +
                    ', ' +
                    moment(day.end_date * 1000).format('HH:mm') +
                    '</span>'
            }
            return result
        },

        answerShowing(answer, checklist) {
            return answer.is_visible
        },

        conditionsCompliance(answer) {
            if (answer.parent_id) {
                var compliance = true
                var parentItem = { ...this.data.items[answer.parent_id] }
                var answerCondition = { ...answer.conditions.values[Object.keys(answer.conditions.values)[0]] }

                // IF PARENT HAS NOT ANSWER
                if (parentItem.value == null) {
                    return false
                }

                // CHECK IF CONDITIONS ARE TRUE
                switch (answerCondition.operator) {
                    case '!empty':
                        if (parentItem.value === null) {
                            compliance = false
                        }
                        break

                    case '==':
                        if (parentItem.value != answerCondition.value) {
                            compliance = false
                        }
                        break

                    case '!=':
                        if (parentItem.value == answerCondition.value) {
                            compliance = false
                        }
                        break

                    case '>':
                        if (parentItem.value <= answerCondition.value) {
                            compliance = false
                        }
                        break

                    case '>=':
                        if (parentItem.value < answerCondition.value) {
                            compliance = false
                        }
                        break

                    case '<':
                        if (parentItem.value >= answerCondition.value) {
                            compliance = false
                        }
                        break

                    case '<=':
                        if (parentItem.value > answerCondition.value) {
                            compliance = false
                        }
                        break

                    case '=':
                        if (parentItem.value != answerCondition.value) {
                            compliance = false
                        }
                        break

                    case '<>':
                        var number1 = answerCondition.value.split(',')[0]
                        var number2 = answerCondition.value.split(',')[1]
                        var min = Math.min(number1, number2)
                        var max = Math.max(number1, number2)

                        if (!(parentItem.value > min && parentItem.value < max)) {
                            compliance = false
                        }
                        break

                    case '!<>':
                        var number1 = answerCondition.value.split(',')[0]
                        var number2 = answerCondition.value.split(',')[1]
                        var min = Math.min(number1, number2)
                        var max = Math.max(number1, number2)

                        if (parentItem.value > min && parentItem.value < max) {
                            compliance = false
                        }
                        break

                    default:
                        break
                }

                return compliance
            } else return true
        },

        isExtended(answer) {
            return this.extendedAnswers.indexOf(answer.type) != -1 && answer.value
        },

        openMedia(ref) {
            this.$popup.media({ image: ref })
        },

        openFeedback(message) {
            this.$popup.alert({
                title: this.$t('nvgt.report.feedback'),
                textSave: this.$t('popup.default.text_ok'),
                message: message
            })
        },

        getEmployee(id) {
            if (!id) {
                return false
            }
            var employee = this.$store.getters['employee/getAccountEmployee'](id)
            return employee
        },

        getToolTypeName(type) {
            return Object.keys(FORMAT).find((key) => FORMAT[key] === parseInt(type))
        },

        load() {
            // TODO - Get from store task/issue/... type
            this.data.def = {
                date_format: 'YYYY-MM-DD',
                hour_format: 'HH:mm',
                datetime_format: 'YYYY-MM-DD HH:mm',
                has_score: true,
                has_state: true,
                has_timing: true,
                has_mandatory: false,
                is_autosave: true,
                can_create: false,
                item_search: false,
                has_calendar: true,
                temporal_view: false,
                close_when_completed: false,
                auth_users: {
                    pin: 2,
                    review: 2,
                    task: 1
                },
                can_edit: {
                    title: true,
                    state: false,
                    inPast: false,
                    inTime: true,
                    inFuture: true
                },
                item: {
                    itemsTitle: [FORMAT.TITLE, FORMAT.SUBTITLE],
                    itemsImage: [FORMAT.PHOTO, FORMAT.SIGNATURE],
                    itemsInfo: [FORMAT.DOCUMENT, FORMAT.MESSAGE],
                    itemsExcluded: [FORMAT.TITLE, FORMAT.SUBTITLE, FORMAT.DOCUMENT, FORMAT.MESSAGE, FORMAT.LINK],
                    itemsVisibles: [FORMAT.TITLE, FORMAT.SUBTITLE, FORMAT.MESSAGE],
                    can_pospone: true,
                    rate: false, // Esto es por si van a tener puntuaciones en funcion de lo que se responda
                    can_review: true,
                    has_periodicity: true,
                    has_score: false
                }
            }
            if (this.check && this.check.config && this.check.config.dependency_tpl) {
                self.$store.dispatch('tasks/checkDependencyTpl', { tpl: self.check.config.dependency_tpl }).then(function (response) {
                    self.dependency_tpl = response
                })
            }
        }
    },
    filters: {
        moment: function (date, format) {
            return date ? moment(date).format(format) : ''
        }
    },
    created() {
        this.load()
    },
    mounted() {
        if (this.$mq != 'portrait') {
            if (this.reportStatus() == 'intime') {
                this.$snackbar.important({ type: 'info', message: this.$t('nvgt.report.timing.intime_snackbar') })
            }

            if (this.data && this.data.type == 3 && this.data.status == '0') {
                this.$snackbar.important({ type: 'info', message: this.$t('nvgt.report.timing.intime_snackbar') })
            }
        }
    },

    watch: {
        $mq(newValue, oldValue) {
            if (newValue == 'portrait') {
                this.$snackbar.close()
            } else {
                if (this.reportStatus() == 'intime') {
                    this.$snackbar.important({ type: 'info', message: this.$t('nvgt.report.timing.intime_snackbar') })
                }

                if (this.data && this.data.type == 3 && this.data.status == '0') {
                    this.$snackbar.important({ type: 'info', message: this.$t('nvgt.report.timing.intime_snackbar') })
                }
            }
        }
    },
    beforeDestroy() {
        this.$snackbar.close()
    }
}
</script>

<style lang="scss" scoped>
$img-error: img('error2_alert.svg');
$img-feedback: img('alert_info.svg');
$img-attach: img('attach_info.svg');
$img-note: img('comment_aaaaaa.svg');
$img-tick: img('checked_ok.svg');
$img-cross: img('error_alert.svg');

$ans-value-bg: #f3f3f3;

$radius: 3px;
$ans-width: 100%;

.report-container {
    @extend .scrollbar;
    @extend .scrollbar-white;
    text-align: center;
    height: calc(90vh - 150px);
    margin: 60px 0px 30px 0px;
    overflow-x: hidden;
}

.day {
    margin-bottom: 60px;
}

.report {
    @include box-shadow($h: 0px, $v: 0px, $b: 10px, $s: 0px, $c: rgba($color-neutral-200, 1));
    padding: 27px;
    display: table;
    width: 21cm;
    min-height: 29.4cm;
    border-radius: 4px;
    height: fit-content;
    overflow: auto;
    overflow-x: hidden;

    .generated_date {
        @include font-size('s');
        font-family: $text-medium;
        width: 100%;
        text-align: center;
        margin-bottom: 5px;
        color: $color-neutral-600;
    }

    .header {
        overflow: hidden;
        .brand {
            height: 50px;
            display: inline-block;
            float: left;

            &.text {
                @include display-flex();
                @include flex-direction(row);
                @include justify-content(flex-start);
                @include align-items();
                @include font-size('l');
                @include text-ellipsis();
                font-family: $text-bold;
                padding: 5px;
                max-width: calc(100% - 140px);
                height: 50px;
            }

            &.alex {
                text-align: right;
                float: right;

                .image {
                    height: 100%;
                    width: auto;
                    display: inline;
                    margin: 0;
                }
            }

            img {
                height: 100%;
                width: auto;
            }
        }
    }

    .report-header {
        margin: 40px 0 8px 0;
        width: 100%;

        .report-info {
            @include display-flex();
            @include align-items();
            width: max-content !important;

            .report-location,
            .report-date > span,
            .report-employee {
                @include font-size('sm');
                display: inline-block;
                float: left;
                margin-bottom: 5px;
                line-height: 1.6;
                padding-left: 20px;
                width: fit-content;
                color: $color-neutral-600;
                margin-right: 20px;
                font-family: $text;
            }
            .report-location {
                @include background($image: img('location_neutro_s40.svg'), $size: 15px, $position: left center);
                padding-left: 17px;
            }

            .report-date {
                & span {
                    &.date {
                        @include background($image: img('input_datetime_neutro_s40.svg'), $size: 15px, $position: left center);
                        text-transform: capitalize;
                    }
                    &.time {
                        @include background($image: img('input_time_neutro_s40.svg'), $size: 15px, $position: left center);
                        padding-left: 18px;
                    }
                    &.close-date {
                        background: unset;
                        padding-left: unset;
                        display: flex;
                        align-items: center;

                        .avatar {
                            margin-left: 6px;
                            margin-right: 3px;
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }

            .report-employee {
                @include background($image: img('user_neutro_s40.svg'), $size: 15px, $position: left center);
                padding-left: 17px;
            }

            .report-title {
                font-family: $text;
                width: 70%;
                padding-left: 12px;
            }

            .user-tag {
                width: fit-content;

                .avatar {
                    width: 20px;
                    height: 20px;
                }

                .name {
                    @include font-size('sm');
                    color: $color-neutral-600;
                    width: 100%;
                }
            }

            .report-status {
                @include font-size(s);
                @include border-radius(4px);
                font-family: $text-bold;
                color: $color-white;
                display: inline-block;
                width: fit-content;
                padding: 3px 6px;
                margin-left: 10px;

                &.intime {
                    background-color: $color-primary-500;
                }
                &.future {
                    background-color: $color-neutral-500;
                }
            }
        }

        .report-data {
            @include display-flex();
            @include flex-direction(row);
            @include justify-content(flex-end);
            @include align-items(flex-end);
            overflow: initial;
            width: 100%;
            height: fit-content;
            border-bottom: 1px solid $color-neutral-200;
            margin-bottom: 5px;

            .report-title {
                @include font-size('xl');
                color: $color-black;
                width: calc(100% - 150px);
                float: left;

                &.fullwidth {
                    width: 100%;
                }
            }

            .issue-closed {
                @include font-size('sm');
                font-family: $text;
                width: auto;
            }

            .score-number,
            .demora,
            .score-percent {
                @include font-size('ml');
                font-family: $text-bold;
                color: $color-black;
                display: inline-block;
                margin: 10px 10px;
            }

            .na {
                @include align-items();
                @include border-radius(4px);
                @include text-ellipsis();
                @include background($image: img('error_main_s40.svg'), $size: 15px, $position: left 0px center);
                @include font-size('sm');
                padding: 3px 9px 3px 22px;
                text-align: center;
                font-family: $text-bold;
                color: $color-primary-500;
                width: min-content;
                margin: 0 auto;
            }

            .left {
                @include display-flex();
                @include justify-content(flex-end);
                align-self: flex-end;
                float: right;
                max-width: 150px;
                height: 100%;
                width: 150px;

                .score-number {
                    font: $text-bold;
                    color: $color-black;
                }

                .na {
                    @include font-size('sm');
                    color: $color-black;
                    font-family: $text-bold;
                    width: min-content;
                    margin: 0;
                    float: right;
                }

                .score-percent {
                    font: $text-bold;
                    color: $color-black;

                    &.alert {
                        color: $color-error-500;
                    }
                    &.warning {
                        color: $color-warning-500;
                    }
                    &.progress {
                        color: $color-success-500;
                    }
                    &.done {
                        color: $color-success-500;
                    }
                }

                .score-audit {
                    display: inline-block;
                    margin-left: 15px;
                    float: right;
                    padding: 2px 6px;
                    width: fit-content;

                    b {
                        padding-right: 6px;
                    }
                }

                .status-audit {
                    display: inline-block;
                    width: fit-content;
                    padding: 3px 6px;
                    border-radius: 3px;
                    text-align: center;
                    margin-bottom: 3px;

                    &.en_progreso {
                        @include background($color: $color-warning-500);
                        color: $color-white;
                    }
                    &.finalizada {
                        @include background($color: $color-primary-500);
                        color: $color-white;
                    }
                    &.programada {
                        @include background($color: $color-info-500);
                        color: $color-black;
                    }
                }

                .demora {
                    @include font-size('sm');
                    margin-bottom: 4px;
                    color: $color-error-500;
                    float: left;
                    display: inline-block;
                }
                .status {
                    float: right;
                    background-color: $color-neutral-200;
                    padding: 3px 8px;
                    border-radius: 3px;
                    width: fit-content;
                    text-align: left;
                    margin-bottom: 4px;

                    span.text {
                        @include font-size('sm');
                        font: $text-bold;
                        color: $color-black;
                        padding-left: 0px;
                    }
                    .color {
                        margin-right: 5px;
                        border-radius: 100%;
                        display: inline-block;
                        height: 10px;
                        width: 10px;
                    }
                }
            }
        }

        .report-alerts {
            width: 100%;

            .counter {
                @include background($size: 15px, $position: left center);
                @include font-size(sm);
                font-family: $text;
                color: $color-neutral-600;
                padding-left: 20px;
                display: inline-block;
                margin-right: 15px;
                margin-bottom: 15px;

                & span {
                    font-family: $text;
                    color: $color-black;
                    margin-right: 5px;
                }
                &.notes {
                    background-image: img('task_note_neutro_s70.svg');
                }
                &.incidents {
                    background-image: img('task_warning_error_s10.svg');
                }
                &.alerts {
                    background-image: img('task_warning_warning_s10.svg');
                }
                &.errors {
                    background-image: img('task_issue_error_s10.svg');
                }
                &.uncompleted {
                    background-image: img('task_uncompleted_error_s10.svg');
                }
            }
        }
    }

    .reference {
        @include display-flex();
        @include border-radius(3px);
        border: 1px solid $color-primary-100;
        padding: 5px;
        background-color: $color-primary-100;

        .andy {
            @include background($image: img('alex_carita_07.svg'), $size: 50px, $position: center center);
            height: 50px;
            width: 50px;
            margin: 0 15px 0 5px;
        }

        .message {
            @include font-size(sm);
            width: calc(100% - 70px);
            font-family: $text-light;

            b,
            span {
                @include font-size(sm);
            }
        }
    }

    .justify {
        background-color: $color-neutral-200;
        @include border-radius(4px);
        padding: 6px;

        .justify-title {
            @include font-size(sm);
            font-family: $text-bold;
            color: $color-black;
            padding-bottom: 12px;
        }
        .reason {
            @include font-size(s);
            font-family: $text-bold;
            color: $color-black;
            padding-bottom: 3px;
        }
        .reason-text {
            @include font-size(s);
            font-family: $text;
            color: $color-black;
        }
    }

    #content.report-checklist {
        $column-width: 20%;
        $column-max-width: rem(60px);
        $content-width: 80%;

        > .content {
            $top-info: rem(90px);
            $resume-width: rem(150px);
            overflow: hidden !important;
            padding-top: 0;
            padding-bottom: 0 !important;
            width: 100%;
            .question.child {
                width: $ans-width - 4%;
                margin-left: 4%;
            }
            .detail-box {
                $sidebar-size: rem(40px);
                $collapse-button: rem(40px);
                $content-padding: rem(0px);
                $detail-bg: $default-bg-color;
                $detail-text-color: $color-primary-800;

                position: relative;
                padding: 0;
                padding-left: $content-padding;
                padding-bottom: $menu-aux-height;
                width: 100%;
                background-color: $detail-bg;
                height: calc(100% - 200px);
                overflow: hidden;

                .exam {
                    @include border-radius($default-border-radius);
                    height: 100%;
                    overflow: auto;

                    .item {
                        display: -webkit-inline-box;
                        display: -ms-inline-flexbox;
                        display: inline-flex;
                        -ms-flex-wrap: wrap;
                        flex-wrap: wrap;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: end;
                        -ms-flex-pack: end;
                        justify-content: flex-end;
                        background-clip: padding-box;
                        height: -moz-fit-content;
                        height: -webkit-fit-content;
                        height: fit-content;
                        width: 100%;
                        padding: 0;
                        overflow: hidden;
                        margin: 0;
                        -webkit-transition-duration: 0.2s;
                        transition-duration: 0.2s;
                        height: auto;
                        min-height: 3.125rem;

                        .answer {
                            @include background($image: img('alex_carita_07.svg'), $position: left 10px center, $size: 50px);
                            width: 100%;
                            background-color: $color-neutral-300;
                            display: -webkit-inline-box;
                            display: -ms-inline-flexbox;
                            display: inline-flex;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            -ms-flex-wrap: wrap;
                            flex-wrap: wrap;
                            width: 100%;
                            min-height: 4rem;
                            padding-right: 10px;
                            padding-left: 60px;

                            .line {
                                padding: 0 10px;

                                .statement {
                                    font-size: 1.125rem;
                                    width: 100%;
                                    // color: #222;
                                    font-family: 'Avenir-Regular';

                                    .answer-title {
                                        @include font-size(sm);
                                        color: #374673;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .check-description {
                @include background($color: shade($default-bg-color, 4%), $image: img('file_attach_aaaaaa.svg'), $position: left 12px center, $size: 35px);
                width: 100%;
                padding: 16px 20px 16px 80px;
                margin: 0 auto 0 auto;

                .alex {
                    @include background($image: img('menu_logbook_aaaaaa.svg'), $size: contain, $position: left bottom);
                    width: 40px;
                    height: 40px;
                    display: inline-block;
                    margin-right: 6px;
                }

                .message {
                    @include font-size(sm);
                    font-family: $text;
                    color: $color-primary-800;
                    display: inline-block;
                    overflow: visible;
                    width: auto;
                    min-height: 20px;
                    height: auto;

                    .more {
                        color: $color-primary-500;
                        cursor: pointer;
                    }
                }
            }
            .top-container {
                background-color: $default-bg-color;
                width: 100%;
                padding: 12px;
                padding-bottom: 0px;
                height: auto;
            }
            .template-sections {
                width: 100%;
                height: 50px;
            }
            &.desktop {
                .top-container {
                    background-color: $default-bg-color;
                    width: 100%;
                    padding: 12px;
                    padding-bottom: 0px;
                    max-height: 95px;
                }
                // Calcular la altura de todo el contenido top que es fijo para el listado
                .detail-box {
                    height: calc(100% - 73px);
                    &.with-sections {
                        height: calc(100% - 120px);

                        &.opened-info {
                            height: calc(100% - 145px);
                        }
                    }
                    &.opened-info {
                        height: calc(100% - 85px);
                    }
                }
            }
            &.landscape {
                // Calcular la altura de todo el contenido top que es fijo para el listado
                .detail-box {
                    height: calc(100% - 68px);
                    width: calc(100% - 68px);
                    &.with-sections {
                        height: calc(100% - 115px);
                        width: calc(100% - 115px);
                        &.opened-info {
                            height: calc(100% - 135px);
                            width: calc(100% - 135px);
                        }
                    }
                    &.opened-info {
                        height: calc(100% - 86px);
                        width: calc(100% - 86px);
                    }
                }
            }

            .justify-description {
                @include display-flex();
                @include align-items();
                @include background($color: shade($default-bg-color, 4%), $image: img('alexhead_03.svg'), $position: left 20px center, $size: 50px);
                width: 100%;
                padding: 16px 20px 16px 80px;
                margin: 0 auto 0 auto;
                $jd-button: rem(130px);
                .message {
                    @include font-size(sm);
                    font-family: $text;
                    color: $color-primary-800;
                    display: inline-block;
                    overflow: visible;
                    width: calc(100% - #{$jd-button});
                    min-height: 20px;
                    height: auto;
                    padding-right: 12px;

                    > span {
                        @include keepFormat();
                        font-family: $text;
                    }
                }

                .action {
                    @include interaction();
                    @include border-radius(3px);
                    @include background($color-warning-500);
                    @include font-size(sm);
                    color: $color-white;
                    font-family: $text-bold;
                    text-align: center;
                    padding: 9px;
                    width: $jd-button;
                    min-height: 35px;
                    display: inline-block;

                    @include bgHover($color-warning-500);
                }
            }

            .get-last-values {
                text-align: center;

                .message {
                    @include interaction();
                    @include border-radius($default-border-radius);
                    @include background($color: $color-primary-100, $image: img('download_primary.svg'), $position: right 12px center, $size: 22px);
                    @include font-size(m);
                    font-family: $text;
                    color: $color-primary-800;
                    padding: 12px 42px 12px 12px;
                    display: inline-block;

                    @include bgHover($color-primary-100);
                }
            }

            .check-available {
                @include background($color: shade($default-bg-color, 4%), $image: img('countdown_primary.svg'), $position: left 26px center, $size: 30px);
                width: 100%;
                padding: 16px 20px 16px 80px;
                margin: 0 auto 0 auto;

                .alex {
                    @include background($image: img('alexhead_04.svg'), $size: contain, $position: left bottom);
                    width: 40px;
                    height: 40px;
                    display: inline-block;
                    margin-right: 6px;
                }

                .message {
                    @include font-size(sm);
                    font-family: $text;
                    color: $color-primary-800;
                    display: inline-block;
                    overflow: visible;
                    width: auto;
                    max-width: calc(100% - 40px - 6px);
                    min-height: 20px;
                    height: auto;
                }
            }

            .question.complete {
                .check {
                    @include border-radius(100%);
                    @include background($size: 16px, $color: $color-white);
                    border: 2px solid #eee;
                }
                .check.checked {
                    // @include background($size: cover, $image: img("checked_primary.svg") );
                    @include background($size: cover, $image: img('checked_ok.svg'));
                    border: none;
                }
            }
        }

        > .column.left {
            $content-space: 6px;

            width: $column-width !important;
            max-width: $column-max-width !important;
            // height: calc( 100% - ( #{$alex-app-padding} * 3) ) !important;

            .timeframes {
                .tframe {
                    padding: 6px 0px 6px 6px;

                    .graph.chart {
                        width: 30px;
                        height: 30px;
                    }
                    .title.condensed {
                        @include font-size(s, $important: true);
                        @include text-ellipsis();
                    }

                    &.alert {
                        position: relative;

                        &:before {
                            background-image: none !important;
                            min-width: 8px;
                            height: 8px;
                        }
                    }
                }
            }
        }
    }
}

.report-container.opened {
    .header {
        background-image: img('up_dark.svg');
    }
}

.report.attachments,
.report.feedback {
    display: none;

    .attach,
    .feed {
        clear: both;
        margin-bottom: 1cm;
    }
    .report-subtitle {
        @include font-size(16px);
        color: #444;
        margin: 0;
        padding: 0;
        padding-left: 20px;
    }

    .title {
        @include background($position: left center, $size: 15px);
        @include font-size(16px);
        color: #444;
        margin: 0;
        padding: 0;
        padding-left: 18px;
    }
}
.report.attachments {
    .title {
        background-image: img('attach_dark.svg');
    }

    .statement {
        @include font-size(14px);
        color: #666;
    }

    .image {
        display: inline-block;
        max-height: 28cm;
        // width: 100%;
        max-width: 100%;

        &.sign {
            width: 350px;
        }
    }
}
.report.feedback {
    .title {
        background-image: img('alert_dark.svg');
    }

    .message {
        @include font-size(16px);
        padding: 0px 6px 3px 6px;
        width: 100%;
        color: #888;

        b {
            @include font-size(16px);
            color: #888;
        }

        ul {
            list-style: disc;
            padding: 5px 0;
            padding-left: 30px;
        }

        li {
            @include font-size(16px);
            color: #888;
        }
    }
}

@media print {
    .report {
        min-height: auto !important;
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
#content .right {
    width: 100% !important;
}
#detail-content.content.right.portrait {
    height: 100% !important;
    width: 100% !important;
    margin-top: 0px !important;
    padding: 0px !important;
    .detail-box {
        background: transparent;
        height: calc(100% - 58px);
        padding-bottom: 0px;
        padding-top: 10px;

        .exam {
            height: calc(100% - 73px);
            overflow: auto;

            .justify-description,
            .check-available {
                padding: 8px 20px 8px 50px;
                background-size: 25px;
                background-position: left 10px center;

                .message {
                    @include font-size(xs);
                    line-height: 0.75rem;
                    width: calc(100% - 100px);
                }
                .action {
                    width: 100px;
                    @include font-size(xs);
                    line-height: 0.75rem;
                }
            }
            .check-available {
                .message {
                    width: 100%;
                    max-width: 100%;
                }
            }
            .check-description {
                padding: 8px 20px 8px 50px;
                background-size: 25px;
                background-position: left 10px center;

                .message {
                    @include font-size(xs);
                    line-height: 0.75rem;
                }
            }
            .answer {
                .line {
                    .statement {
                        .answer-title {
                            @include font-size(xs, $important: true);
                        }
                    }
                }
            }

            .get-last-values {
                .message {
                    @include font-size(xs);
                    line-height: normal;
                }
            }
        }
        &.opened-info {
            height: calc(100% - 84px);
            padding-bottom: 0px;
        }
        &.with-sections {
            height: calc(100% - 130px);
            padding-bottom: 0px;

            .exam {
                height: calc(100% - 33px);
            }

            &.opened-info {
                height: calc(100% - 135px);
                padding-bottom: 0px;
            }
        }
    }
    .top-container {
        height: auto;
        max-height: 115px;
        padding: 0px;
    }
}
</style>
