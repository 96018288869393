<template>
    <div id="multiaccount-searchbar">
        <input id="multiaccount-searchar-input" type="text" v-model.trim="input" :placeholder="$t('multiaccount.placeholder')" @input="emitInputValue" />
    </div>
</template>
<script>
export default {
    data() {
        return {
            input: ''
        }
    },
    methods: {
        emitInputValue() {
            this.$emit('emitSearchInputValue', this.input)
        }
    }
}
</script>
<style lang="scss">
#multiaccount-searchbar {
    display: flex;
    justify-content: center;
    padding: 20px 0;

    #multiaccount-searchar-input {
        @include font-size('m');
        @include border-radius(4px);
        font-family: $text;
        color: $color-neutral-300;
        padding: 10px 6px;
        height: 40px;
        width: 280px;
        background-color: #fff !important;
        border: 1px solid $color-neutral-300;
    }
}
</style>
