<template>
    <div class="empty-container bigEntrance" :class="[type, $mq, customClass]">
        <div class="content hide-scrollbar">
            <div class="andy-face tossing"></div>
            <div class="title-content" v-html="title" :class="{ 'no-btn': buttonText == undefined }"></div>
            <div class="description" v-html="description"></div>
            <div class="button-action" v-if="(buttonText != undefined && buttonType == 'create' && userLogged && userLogged.password != null) || $route.name == 'ConfigPage' || $route.name == 'Home'">
                <button class="btn pulse" :class="buttonClass" @click="actionButton()" v-html="buttonText"></button>
                <!-- <Button :bType="buttonType" :customClass="buttonClass" :bLabel="buttonText" :bCallback="actionButton"></Button> -->
            </div>
            <div class="optional-link" v-if="link != undefined">
                <router-link :to="link">{{ linkText }}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EmptyPage',
    props: {
        title: String,
        description: String,
        link: undefined,
        linkText: undefined,
        buttonText: undefined,
        buttonType: { type: String, default: 'create' },
        buttonClass: { type: String, default: 'add' },
        type: { type: String, default: 'default' },
        customClass: { type: String, default: '' }
    },
    computed: {
        userLogged() {
            var user = this.$store.getters['loginUser/getLocalEmployee']
            var roleUserLogged = this.$store.getters['employee/getEmployee'](user)
            return roleUserLogged
        }
    },
    data() {
        return {
            openReport: true
        }
    },
    methods: {
        actionButton() {
            this.$emit('buttonAction')
        }
    }
}
</script>

<style lang="scss" scoped>
.empty-container {
    @include display-flex();
    @include justify-content();
    @include align-items(center);
    width: 100%;
    height: calc(100% - 70px);
    pointer-events: all;
    z-index: 10;

    .content {
        @include background($image: img('ellipse_neutro_01.svg'), $size: 80%);
        @include display-flex();
        @include flex-direction(column);
        @include align-items(flex-start);
        @include justify-content(center);
        padding-left: 0px;
        z-index: 2;
        height: auto;
        width: auto;
        text-align: left;
        position: relative;
        min-width: 400px;
        min-height: 400px;
        padding-top: 40px;

        .andy-face {
            @include background($image: img('alex_carita_05.svg'));
            content: '';
            display: inline-block;
            height: 260px;
            width: 100px;
            position: absolute;
            top: calc(50% - 150px + 10px);
            left: 5px;
        }

        .title-content,
        .description {
            padding-left: 50px;
        }

        .title-content {
            @include font-size(xl);
            font-family: $text-bold;
            color: $color-black;

            &.no-btn {
                margin-top: -30px;
            }
        }
        .description {
            @include font-size(m);
            font-family: $text;
            color: $color-black;
            padding-top: 10px;
            max-width: 80%;
        }
        .optional-link {
            @include font-size(sm);
            font-family: $text-medium;
            color: $color-primary-500;
            padding-top: 6px;
        }
        .button-action {
            // padding-top: 30px;

            .btn {
                animation-iteration-count: 2;
                -webkit-animation-iteration-count: 2;
                margin: 0;

                &.add {
                    @include background($image: img('add_ffffff.svg'), $size: 12px, $position: 8px center);
                    background-color: $color-success-500;
                    border-radius: 60px;
                    padding-left: 25px;
                }
            }
        }
    }
    .content .andy-face {
        // @include background($image: img('alex_carita_05.svg'));
        // content: '';
        // display: inline-block;
        // height: 260px;
        // width: 100px;
        // position: absolute;
        // top: calc(50% - 150px + 10px);
        // left: 5px;
    }

    // &.issues {
    //     .content .andy-face {
    //         @include background($image: img('alex_carita_12.svg'));
    //         content: '';
    //         top: calc(50% - 160px + 10px);
    //         margin-right: 10px;
    //     }
    //     .content {
    //         @include background($image: img('ellipse_neutro_03.svg'), $size: 80%);
    //         @include display-flex();
    //         @include justify-content(flex-start);
    //     }
    //     .title-content {
    //         @include font-size(xl);
    //         font-family: $text-bold;
    //         color: $neutro-s90;
    //         height: auto;
    //         max-width: 300px;
    //         text-align: right;
    //         margin-top: 90px;
    //         padding-bottom: 10px;
    //         margin-left: 10px;
    //     }
    //     .description {
    //         margin-left: 10px;
    //     }
    //     .button-action {
    //         .add {
    //             margin-left: 60px;
    //         }
    //     }
    // }

    // NEW UPDATE OF EMPTY PAGE
    .content .andy-face {
        @include background($image: img('alex_carita_01.svg'));
        content: '';
        display: inline-block;
        height: 260px;
        width: 100px;
        position: absolute;
        top: calc(50% - 150px + 10px);
        left: unset;
        right: 0px;
    }
    .content {
        @include background($image: img('ellipse_neutro_02.svg'), $size: 90%);
        @include align-items(flex-start);
        text-align: unset;
        text-align: left;
        margin-left: 0px;
    }
    .title-content {
        @include font-size(xl);
        font-family: $text-bold;
        color: $color-black;
        height: auto;
        max-width: 300px;
        text-align: left;
        margin-top: -30px;
        padding-bottom: 10px;
    }
    .description {
        @include font-size(ml);
        font-family: $text;
        color: $color-black;
        max-width: 250px;
        height: auto;
        text-align: left;
    }
    .button-action {
        padding-top: unset;
        margin: 30px auto;
        margin-bottom: 0px;

        .add {
            margin-right: 40px;
        }

        .btn:last-child {
            margin-right: 0px;
        }
    }

    &.printer {
        .content {
            .andy-face {
                @include background($image: img('alex_carita_01.svg'));
                content: '';
                display: inline-block;
                height: 260px;
                width: 100px;
                position: absolute;
                top: calc(50% - 150px + 10px);
                left: unset;
                right: 0px;
            }
        }
        .content .andy-face {
            // @include background($image: img('alex_carita_01.svg'));
            // content: '';
            // display: inline-block;
            // height: 260px;
            // width: 100px;
            // position: absolute;
            // top: calc(50% - 150px + 10px);
            // left: unset;
            // right: 0px;
        }
    }

    &.login {
        .content {
            @include background($image: img('ellipse_neutro_04.svg'), $size: 100%);

            .title-content {
                max-width: 305px;
                height: 46px;
                line-height: 27px;
            }
            .description {
                font-family: $text;
                max-width: 350px;
            }
            .btn {
                margin: 0;

                &.add {
                    @include background($image: img('help_circle_sharp_2.svg'), $size: 21px, $position: 10px center);
                    background-color: $color-primary-500;
                    border-radius: 60px;
                    padding-left: 35px;
                }
            }
        }
    }
    &.error {
        .content {
            @include background($image: img('ellipse_warning.svg'), $size: 100%);

            .title-content {
            }
            .button-action {
                padding-top: 10px;

                .back {
                    @include border-radius(rem(60px));
                    @include background($image: img('left_ffffff.svg'), $position: left 10px center, $size: 13px);
                    background-color: $color-primary-500;
                    padding-left: 28px;
                }
            }
        }
        .content .andy-face {
            @include background($image: img('alex_carita_16.svg'));
            content: '';
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
.empty-container.portrait {
    width: 100%;
    max-width: 350px;
    min-width: unset;
    height: fit-content;
    margin: 30px auto;
    @include background($image: img('ellipse_neutro_01.svg'), $size: 100%, $position: top 40px center);

    .content {
        height: fit-content;
        min-height: calc(300px);
        // BACKGROUND ELLIPSIS HIDE
        // background-image: none;
        padding: 20px 0px 0px !important;
        // max-width: 100%;
        margin: 0 auto;
        min-width: 100%;

        .andy-face {
            height: 200px;
            width: 90px;
            position: absolute;
            top: calc(50% - 150px + 10px);
            right: 5px;
        }

        &::before {
            // height: 200px;
            // width: 90px;
            // position: absolute;
            // top: calc(50% - 150px + 10px);
            // right: 5px;
        }

        .title-content,
        .description {
            width: 100% !important;
            max-width: none;
            padding-right: 80px !important;
            padding-left: 50px !important;
            padding-left: 40px;
        }

        .title-content {
            @include font-size(ml);
        }

        .description {
            @include font-size(sm);
            padding-right: 30px;
        }

        .button-action {
            width: 100%;
            text-align: center;
        }
    }

    // &.record {
    //     .content {
    //         height: fit-content;
    //         min-height: calc(300px);
    //         background-image: none;
    //         padding: 20px 0px 0px !important;
    //         // max-width: 100%;
    //         margin: 0 auto;
    //         min-width: 100%;

    //         .andy-face {
    //             @include background($image: img('alex_carita_05.svg'), $size: 100%, $position: top 40px center);
    //             height: 200px;
    //             width: 90px;
    //             position: absolute;
    //             top: calc(50% - 150px + 10px);
    //             left: 5px;
    //             right: none;
    //         }
    //         &::before {
    //             // @include background($image: img('alex_carita_05.svg'), $size: 100%, $position: top 40px center);
    //             // height: 200px;
    //             // width: 90px;
    //             // position: absolute;
    //             // top: calc(50% - 150px + 10px);
    //             // left: 5px;
    //             // right: none;
    //         }
    //     }
    // }

    .printer {
    }
    &.checklist {
        margin: 0px auto;
        height: calc(100% - 50px);
        .content {
            padding: 0px;
        }
        background-position-y: center;
    }
}
</style>
