<template>
    <div class="banner" :class="{ 'banner-edit-mode': editMode }">
        <span class="bannerText">{{ this.editMode ? $t('banner.editOn') : $t('banner.editOff') }}</span>
        <Button
            :bType="editMode ? 'editOn' : 'editOff'"
            :bLable="editMode ? 'button.editOn' : 'button.editOff'"
            customClass="editOff"
            :bCallback="toggleEditMode"
            :key="editMode.toString()"
        ></Button>
    </div>
</template>
<script>
export default {
    data() {
        return {
            editMode: false
        }
    },
    computed: {},
    methods: {
        toggleEditMode() {
            this.editMode = !this.editMode
            this.$emit('templateToggle', this.editMode)
        }
    }
}
</script>
<style lang="scss">
.banner {
    // width: 21cm;
    width: 100%;
    height: max-content;
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    background-color: $color-neutral-300;
    // position: absolute;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;

    @media (min-width: 500px) {
        flex-direction: row;
        justify-content: space-between;
        // margin: 7px 0px 7px 0px;
    }

    @media (min-width: 1025px) {
        width: 21cm;
        position: absolute;
    }
}

.banner-edit-mode {
    background-color: $color-warning-100;
}

.bannerText {
    font-size: 12px;
    color: $color-black;
}
</style>
