<template>
    <div class="filter-box">
        <div class="title">{{ $t('assets.filter.filter_data') }}</div>

        <SuperviseChecklistsFilterInputs
            :currentFilters="currentFilters"
            :activeFilters="activeFilters"
            @inputChange="(params) => updateCurrentFilters(params)"
        />

        <FilterActions
            :showClearFiltersButton="showClearFiltersButton"
            :clearFiltersCallback="resetFilters"
            :filterCallback="applyFilters"
            :changesDetected="changesDetected"
        />
    </div>
</template>

<script>
import i18n from '@/i18n'
import Tags from '@/components/ui/Tags'
import FilterActions from '../../../ui/FilterActions.vue'
import SuperviseChecklistsFilterInputs from './SuperviseChecklistsFilterInputs.vue'
import { getListOfIds } from '../../../../../public/js/utils'

const defaultFilters = {
    sites: '',
    template: [],
    timeframe: [],
    category: [],
    tags: [],
    type_date: 'week',
    demora: false,
    score: [0, 100],
    start_date: '',
    end_date: '',
    tplType: ''
}

export default {
    name: 'SuperviseChecklistsFiltersBox',
    components: {
        Tags,
        FilterActions,
        SuperviseChecklistsFilterInputs
    },
    props: {
        applyFilter: { type: Boolean, default: false },
        pagination: { type: Object, default: false },
        sortBy: { type: undefined, default: false },
        sortType: { type: undefined, default: false },
        changePagination: { type: Boolean, default: false },
        selectedView: { type: Object }
    },
    data() {
        return {
            // Filters states
            currentFilters: structuredClone(defaultFilters),
            emptyFilters: structuredClone(defaultFilters),
            activeFilters: structuredClone(defaultFilters),

            // Filter variables
            changesDetected: false
        }
    },
    computed: {
        showClearFiltersButton() {
            return JSON.stringify(this.currentFilters) !== JSON.stringify(this.emptyFilters)
        },
        resetFilterState() {
            return this.$store.getters['tasks/getResetFiltersState']
        }
    },

    methods: {
        updateCurrentFilters(params) {
            const { inputName, value } = params
            const clonedFilters = structuredClone(this.currentFilters)

            if (inputName === 'tplType') {
                clonedFilters.tplType = params.value
            }

            if (inputName === 'type_date' && value !== 'custom') {
                clonedFilters.start_date = ''
                clonedFilters.end_date = ''
            }

            if (inputName === 'sites') {
                clonedFilters.locations = ''
                clonedFilters.groups = ''
            }

            if (value && Array.isArray(value)) {
                clonedFilters[inputName] = [...value]
            } else {
                clonedFilters[inputName] = value
            }

            this.currentFilters = clonedFilters

            this.$store.commit('tasks/setCurrentFilters', this.currentFilters)
        },

        // if receives an array of at least one object, transforms it to an array of Ids and
        // returns a JSON of it. If not, returns an empty string
        getIdsList(filterName, defaultValue = '') {
            const self = this

            if (!filterName) {
                return
            }

            const filterList = self.currentFilters[filterName]

            if (filterList) {
                if (Array.isArray(filterList) && filterList.every((e) => typeof e === 'string')) {
                    return filterList
                }

                if (Object.values(filterList).length) {
                    const listOfIds = filterList.map((filter) => {
                        if (filter.id !== null) {
                            return filter.id
                        }
                    })

                    return JSON.stringify(listOfIds)
                }
            } else return defaultValue
        },

        transformFiltersForDB(filters) {
            const filtersForDB = {
                tplType: filters.tplType ? filters.tplType : 'all',
                template: filters.template && filters.template.length > 0 ? JSON.stringify(getListOfIds(filters.template)) : '',
                timeframe: filters.timeframe && filters.timeframe.length > 0 ? JSON.stringify(getListOfIds(filters.timeframe)) : '',
                category: filters.category && filters.category.length > 0 ? JSON.stringify(getListOfIds(filters.category)) : '',
                tags: filters.tags && filters.tags.length > 0 ? JSON.stringify(getListOfIds(filters.tags)) : '',
                start_date: filters.type_date ? filters.type_date : 'week',
                end_date: null,
                demora: filters.demora ? filters.demora : false,
                score: filters.score != [0, 100] ? filters.score.join(',') : '0, 100'
            }

            if (filters.type_date === 'custom') {
                filtersForDB.start_date = filters.start_date ? moment(filters.start_date).format('YYYY-MM-DD') : null
                filtersForDB.end_date = filters.end_date ? moment(filters.end_date).format('YYYY-MM-DD') : null
            }

            const locations = this.currentFilters.locations ? this.currentFilters.locations : null
            const groups = this.currentFilters.groups ? this.currentFilters.groups : null

            if (locations) {
                filtersForDB.location = JSON.stringify(getListOfIds(locations))
                filtersForDB.groups = ''
            }

            if (groups) {
                filtersForDB.groups = JSON.stringify(getListOfIds(groups))
                filtersForDB.locations = ''
            }

            return filtersForDB
        },

        applyFilters() {
            const amplitudeProps = {
                page: 'SuperviseChecklists'
            }

            const employee = this.$store.getters['login/getLocalEmployee']
            if (employee) {
                amplitudeProps.employee = {
                    fullname: `${employee.name} ${employee.surname}`,
                    account_name: employee.account_name,
                    employee_email: employee.email
                }
            }

            this.$amplitude.track('Uses of filters', {
                ...amplitudeProps,
                filters: this.currentFilters
            })

            //En caso de que se haya paginado con filtros aplicados, se resetea la paginacion pero no se hace 2 peticiones ANDY 1271
            if (this.resetFilterState) {
                this.$store.commit('tasks/setResetFiltersState', false)
            }

            var self = this

            self.$overlay.loading()

            const iApplyFilters = this.changesDetected ? 1 : 0
            let pagenum = this.pagination.page
            if (iApplyFilters) {
                this.$emit('resetPagination')
                pagenum = 1
            }

            this.changesDetected = false

            if (this.$store.getters['tasks/getCanLoadList']) {
                const filters = this.transformFiltersForDB(this.currentFilters)

                const loadListParams = {
                    applyFilters: iApplyFilters,
                    pagenum: pagenum,
                    number_items: this.pagination.itemsPerPage,
                    sortBy: this.sortBy,
                    sortType: this.sortType,
                    ...filters
                }

                this.$store.dispatch('tasks/loadList', loadListParams).then(() => {
                    self.activeFilters = JSON.parse(JSON.stringify(self.currentFilters))

                    self.$store.commit('tasks/setFiltersActive', self.activeFilters)

                    self.$store.commit('reports/setReportsToExportChecklistsFilters', {
                        sortType: this.sortType,
                        sortBy: this.sortBy.join(','),
                        ...filters
                    })

                    self.$overlay.hide()
                    self.$emit('resetPageDatatable')
                    self.$emit('filtersApplied')

                    if (JSON.stringify(self.activeFilters) != JSON.stringify(self.emptyFilters)) {
                        self.$emit('reset', true)
                    } else {
                        self.$emit('reset', false)
                    }
                })
            } else {
                self.activeFilters = JSON.parse(JSON.stringify(self.currentFilters))
                self.$overlay.hide()
                self.$emit('resetPageDatatable')

                if (JSON.stringify(self.activeFilters) != JSON.stringify(self.emptyFilters)) {
                    self.$emit('reset', true)
                } else {
                    self.$emit('reset', false)
                }
            }
        },

        resetFilters() {
            this.$emit('resetPagination')

            this.$overlay.loading()
            //reset filters for PDF

            this.$store.commit('tasks/setResetFiltersState', true)
            const defaultFilters = {
                sites: '',
                template: [],
                timeframe: [],
                category: [],
                type_date: 'week',
                demora: false,
                score: [0, 100],
                start_date: '',
                end_date: '',
                tplType: ''
            }

            // this.currentFilters = structuredClone(this.emptyFilters)
            // this.activeFilters = structuredClone(this.emptyFilters)

            // this.$store.commit('tasks/setFiltersActive', this.currentFilters)

            this.changesDetected = false

            this.$emit('on-reset-filters')

            this.$overlay.hide()
        },

        handleChangePagination(page) {
            this.$overlay.loading()

            let self = this
            let iApplyFilters = this.changesDetected ? 1 : 0
            let pagenum = page ? page : this.pagination.page // Es necesario que siempre vuelva a la pagina inicial

            if (this.resetFilterState) {
                this.$store.commit('tasks/setResetFiltersState', false)
                return
            }

            const filters = this.transformFiltersForDB(this.activeFilters)

            const loadListParams = {
                applyFilters: iApplyFilters,
                pagenum: pagenum,
                number_items: this.pagination.itemsPerPage,
                sortBy: this.sortBy,
                sortType: this.sortType,
                ...filters
            }

            this.$store.dispatch('tasks/loadList', loadListParams).then(function () {
                self.$overlay.hide()
                self.$emit('resetPageDatatable')
                let reset = JSON.stringify(self.currentFilters) != JSON.stringify(self.emptyFilters)
                self.$emit('reset', reset)
            })
        }
    },

    watch: {
        currentFilters: {
            handler: function (val, oldVal) {
                if (JSON.stringify(val) != JSON.stringify(this.activeFilters)) {
                    this.changesDetected = true
                } else {
                    this.changesDetected = false
                }
                this.$store.commit('tasks/setCurrentFilters', this.currentFilters)
            },
            deep: true
        },

        sortType: {
            handler: function (val, oldVal) {
                if (oldVal !== false && oldVal !== undefined) {
                    this.handleChangePagination()
                }
            },
            immediate: true
        },

        selectedView(newSelectedView, oldSelectedView) {
            if (!newSelectedView) return

            this.currentFilters = structuredClone(newSelectedView.data)
            this.$store.commit('tasks/setCurrentFilters', this.currentFilters)

            this.applyFilters()
        }
    },

    mounted() {
        this.currentFilters = structuredClone(this.$store.getters['tasks/getFiltersActive'])
        this.activeFilters = structuredClone(this.$store.getters['tasks/getFiltersActive'])
    },

    created() {}
}
</script>

<style lang="scss" scoped>
// Busca en assets :^)
</style>
